.car-table {
    .ant-table {
        background: white;
    }

    .ant-btn-primary {
        background-color: #1890ff;
        border-color: #1890ff;
    }

    .table-name-container {
        display: flex;
        align-items: center;
    }

    .table-image {
        max-width: 150px; 
        max-height: 150px;
        margin-right: 10px;
        border-radius: 4px; 
    }

    .table-name {
        font-weight: 500;
    }

    @media (max-width: 768px) {
        .ant-table {
            overflow-x: auto;
        }
    }
}
