.car-cards-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}

.car-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .image-container {
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        
        .time-left {
            position: absolute;
            top: 8px;
            right: 8px;
            background-color: #333;
            color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
        }
    }

    .car-details {
        padding: 16px;

        .car-name {
            font-size: 18px;
            font-weight: bold;
            margin: 0;
        }

        .car-price {
            font-size: 16px;
            color: #555;
            margin: 8px 0;
        }

        .car-location {
            font-size: 14px;
            color: #777;
        }

        .view-button {
            width: 100%;
            padding: 8px 0;
            background-color: #4a90e2;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            margin-top: 16px;
        }
    }
}