.filter-year-container {
    display: flex;
    align-items: center;
    
  }

.filter-year-input{
    width: 100%;
    max-width: 70px;
    margin-left: 8px;
    margin-right: 8px;
}
  
  