.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1B1B1B;
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    flex-wrap: wrap; /* Allow elements to wrap on smaller screens */
}

.top-bar-left {
    display: flex;
    align-items: center;

    .logo {
        height: 40px;
        margin-right: 0.5rem;
    }
}

.top-bar-title {
    flex-grow: 1;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.top-bar-right {
    display: flex;
    align-items: center;

    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 1rem;
        padding-right: 30px;
        
        &:hover {
            text-decoration: underline;
        }

        /* Style for email icon */
        .anticon {
            font-size: 1.5rem;
            color: white;
        }
    }
}

/* Media Query for mobile responsiveness */
@media (max-width: 768px) {
    .top-bar {
        padding: 0.5rem 1rem;
    }

    .top-bar-left .logo {
        height: 30px;  /* Reduce logo size on mobile */
    }

    .top-bar-title {
        font-size: 1.2rem; /* Adjust title size */
    }

    .top-bar-right a {
        font-size: 1.2rem;
    }
}
