.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .top-bar {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
    }

    .main-content {
        display: flex;
        margin-top: 60px; 
        height: calc(100vh - 60px); 
        overflow: hidden;
        padding-top: 15px;

        .filter-sidebar {
            width: 250px;
            padding: 1rem;
            background-color: #f2f2f2;
            overflow-y: auto;
            transition: width 0.3s;

            &.collapsed {
                width: 0;
                padding: 0;
                overflow: hidden;
            }
            
            .collapse-button {
                display: block;
                margin-top: 1rem;
                width: 100%;
            }
        }

        .table-container {
            flex: 1;
            padding: 1rem;
            overflow-y: auto;
            background-color: #ffffff;
        }

        .expand-button {
            position: fixed;
            top: 70px; 
            left: 10px;
            z-index: 20;
            background-color: #1890ff;
            color: white;
            border: none;
            padding: 10px;
            border-radius: 4px;
        }
    }

    @media (max-width: 768px) {
        .main-content {
            flex-direction: column;
            padding: 15px;

            .filter-sidebar {
                width: 100%;
                padding: 0;
                &.collapsed {
                    display: none;
                }
            }

            .expand-button {
                display: block;
            }
        }
    }
}

